<template>
  <!-- eslint-disable max-len -->
  <div>
    <nav class="uk-navbar" uk-navbar uk-sticky>
      <div class="uk-navbar-left">
        <ul class="uk-navbar-nav">
          <li class="uk-active">
            <router-link to="/">
              <img src="../assets/logo.png" alt="logo" class="logo" />
            </router-link>
          </li>
          <li v-show="isHome" class="uk-visible@m">
            <img src="../assets/bl-pet-firendly.png" class="logo-pf" style=""/>
          </li>
        </ul>
      </div>
      <div class="uk-navbar-right">
        <ul class="uk-navbar-nav uk-visible@s">
          <li class="uk-active">
            <router-link to="/">
              <span class="nav-icon" uk-icon="calendar"></span> Reservaciones</router-link
            >
          </li>

          <li>
            <router-link to="/en-construccion">
              <span class="nav-icon" uk-icon="bookmark"></span> Actividades</router-link
            >
          </li>

          <li>
            <router-link to="/en-construccion">
              <span class="nav-icon" uk-icon="image"></span> Galería</router-link
            >
          </li>

          <li>
            <router-link to="/en-construccion">
              <span class="nav-icon" uk-icon="future"></span> Paquetes y Eventos</router-link
            >
          </li>

          <li>
            <router-link to="/ubicacion-kanaj"
              ><span class="nav-icon" uk-icon="location"></span> Ubicación</router-link
            >
          </li>

          <li>
            <a target="_blank" href="https://kanaj.s3.us-east-2.amazonaws.com/reglamento.pdf">
              <span class="nav-icon" uk-icon="list"></span> Reglamento</a
            >
          </li>

          <li>
            <router-link to="/en-construccion">
              <span class="nav-icon" uk-icon="info"></span> Nosotros</router-link
            >
          </li>

          <!-- <li>
          <router-link to="/">Reservas</router-link>
        </li> -->

          <!-- <li>
          <router-link to="#">Ayuda</router-link>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                        <li><a href="uk-active">Preguntas frecuentes</a></li>
                        <li><a href="#">Protocolos de Salud</a></li>
                        <li><a href="#">Contacto</a></li>
                    </ul>
                </div>
        </li> -->

          <!-- <li>
          <router-link v-if="usuarioAutenticado" to="/reservations" class="nav-icon">Mis reservaciones</router-link>
        </li> -->

          <li>
            <button v-if="usuarioAutenticado" class="uk-button cerrar" @click="cerrarSesion">
              Cerrar Sesión
              <span uk-icon="sign-out"></span>
            </button>
          </li>

          <li>
            <router-link v-if="usuarioAutenticado" to="/reservations">
              <span class="nav-icon" uk-icon="album"></span> Mis reservaciones</router-link
            >
          </li>

          <li>
            <router-link v-if="!usuarioAutenticado" to="/registro">
              <span class="nav-icon" uk-icon="user"></span
            ></router-link>
          </li>
        </ul>
        <a
          href="#"
          class="uk-navbar-toggle uk-hidden@s"
          uk-navbar-toggle-icon
          uk-toggle="target: #sidenav"
        ></a>
      </div>
    </nav>

    <div id="sidenav" uk-offcanvas="flip: true" class="uk-offcanvas">
      <div class="uk-offcanvas-bar">
        <ul class="uk-nav">
          <li class="uk-active">
            <router-link to="/"> <span uk-icon="calendar"></span> Reservaciones</router-link>
          </li>

          <li>
            <router-link to="/en-construccion">
              <span uk-icon="bookmark"></span> Actividades</router-link
            >
          </li>

          <li>
            <router-link to="/en-construccion"> <span uk-icon="image"></span> Galería</router-link>
          </li>

          <li>
            <router-link to="/en-construccion">
              <span uk-icon="future"></span> Paquetes y Eventos</router-link
            >
          </li>

          <li>
            <router-link to="/ubicacion-kanaj"
              ><span uk-icon="location"></span> Ubicación</router-link
            >
          </li>

          <li>
            <a target="_blank" href="https://kanaj.s3.us-east-2.amazonaws.com/reglamento.pdf">
              <span class="nav-icon" uk-icon="list"></span> Reglamento</a
            >
          </li>

          <li>
            <router-link to="/en-construccion"> <span uk-icon="info"></span> Nosotros</router-link>
          </li>

          <li>
            <router-link v-if="usuarioAutenticado" to="/reservations">
              <span uk-icon="history"></span> Mis reservaciones</router-link
            >
          </li>

          <li>
            <router-link v-if="!usuarioAutenticado" to="/registro">
              <span uk-icon="user"></span> Regístrarse</router-link
            >
          </li>

          <li>
            <button v-if="usuarioAutenticado" class="uk-button cerrar" @click="cerrarSesion">
              Cerrar Sesión
              <span uk-icon="sign-out"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['usuarioAutenticado']),
    isHome() {
      return this.$route.name === 'Home';
    },
  },

  methods: {
    ...mapActions(['cerrarSesion']),
    
  },
};
</script>

<style>
.cerrar {
  margin-top: 20px;
}
.logo {
  max-width: 140px;
  height: auto;
  padding: 3px;
}
.logo-pf {
  margin-top: 10px;
  max-width: 80px;
  height: auto;
  padding: 3px;
}
.uk-navbar {
  background-color: white;
}
.nav-icon {
  margin-right: 7px;
}
</style>
