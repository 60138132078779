<template>
  <div>
    <Navbar />
    <v-app>
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Navbar from './components/Navbar.vue';

// Conekta Public ELIAS: key_G5UwbjipYkL9FjYxck1cmKQ

// PROD BCAMP key_Xx6q46rYsxSNrvqzsSxLGrA
// TEST BCAMP key_OjX34Cgk3FJow2FZLEwmu3g

// TEST
// export const CONEKTA_KEY = 'key_G5UwbjipYkL9FjYxck1cmKQ';
// export const SERVER_HOST = 'https://shrouded-garden-55125.herokuapp.com';

// PROD
export const CONEKTA_KEY = 'key_Xx6q46rYsxSNrvqzsSxLGrA';
export const SERVER_HOST = 'https://admin.kanaj.mx';

export default {
  name: 'App',

  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(['cargarLocalStorage']),
  },

  created() {
    this.cargarLocalStorage();
  },
};
</script>

<style>
@font-face {
  font-family: "Kiona";
  src: local("Kiona"), url(./fonts/Tipografias/Kiona-Regular.ttf) format("truetype");
}

#app {
  background-color: #f9f9f9;
}

html body {
  font-family: "Kiona";
}

#app {
  font-family: "Kiona";
}
</style>
