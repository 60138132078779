/* eslint-disable eqeqeq */
<template>
 <!-- eslint-disable vue/no-parsing-error  -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
 <!-- eslint-disable vue/valid-v-bind  -->
 <!-- eslint-disable-next-line vue/valid-v-bind -->
  <!-- eslint-disable max-len -->
  <div class="home">

    <div class="uk-container">

      <div uk-scrollspy="cls: uk-animation-fade; repeat: true">
        <Slider />
      </div>
      <hr class="uk-margin-large"  />

        <h2 uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
          <span class="transparente mls">hol</span>Fechas Disponibles
          <br>
          <span class="transparenterojo mls">hol</span>Fechas No Disponibles
        </h2>

      <div class="uk-grid-column-small uk-grid-row-large  uk-child-width-1-1@s"
        uk-grid>

        <div id="calendarInicio" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">

          <div class="uk-card uk-card-default uk-card-body">
            <label for="Fecha Inicio" class="uk-text-center">Seleccione primer y última noche</label>
            <Calendar label="Fecha Inicio" @paul="fechaHijo" class="calendar"/>
          </div>

          <center class="uk-margin-top uk-margin-bottom" id="buttonNavigate" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
          <router-link
            :to="{
                name: 'Seleccionar',
                query: {
                  fecha: fechas[0],
                  fecha2: fechas[1],
                }
              }"
            class="uk-button app-bg"
            custom v-slot="{ navigate }"
          >
            <span @click="navigate">BUSCAR</span>
          </router-link>
        </center>

        </div>

      </div>

      <hr class="uk-margin-large" />
      <div>
        <div class="uk-panel uk-text-center" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
          Vive la experiencia, Kanaj Bubble Glamping ahora en Durango.
        </div>
        <div class="uk-panel uk-text-center" uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
          RESERVACIONES DISPONIBLES
        </div>
      </div>
    </div>

    <hr class="uk-margin-medium" />

    <div class="uk-container" uk-slider>
      <div
      uk-scrollspy="cls: uk-animation-slide-bottom; delay: 300; repeat: true"
        class="uk-position-relative uk-visible-toggle uk-light"
        tabindex="0"
        uk-slider="center: false; autoplay: true; autoplay-interval: 6000; pause-on-hover: true"
      >
        <div
          class="uk-slider-items uk-grid uk-grid-match"
          uk-height-viewport="offset-top: true; offset-bottom: 30"
          uk-lightbox
        >
          <div>
            <a
              href="https://kanaj.s3.us-east-2.amazonaws.com/logo.92caa6bb.jpg"
              data-caption=""
            >
              <img
                src="https://kanaj.s3.us-east-2.amazonaws.com/logo.92caa6bb.jpg"
                alt=""
                class="slider-image"
              />
            </a>
          </div>

          <div uk-scrollspy-class="uk-animation-slide-top">
            <a
              href="https://kanaj.s3.us-east-2.amazonaws.com/foto_2.ba5f2b8b.jpg"
              data-caption=""
            >
              <img
                src="https://kanaj.s3.us-east-2.amazonaws.com/foto_2.ba5f2b8b.jpg"
                alt=""
              />
            </a>
          </div>

          <div>
            <a
              href="https://kanaj.s3.us-east-2.amazonaws.com/foto_3.290703fc.jpg"
              data-caption=""
            >
              <img
                src="https://kanaj.s3.us-east-2.amazonaws.com/foto_3.290703fc.jpg"
                alt=""
              />
            </a>
          </div>
        </div>

        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div>

      <hr />

      <div class="uk-flex-middle" uk-grid>
        <div class="uk-width-2-3@m">

          <p class="uk-text-center" uk-scrollspy="cls: uk-animation-slide-top; repeat: true">
            <strong>Kanaj Bubble GLamping.</strong>
          </p>
          <p class="uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
            <strong>Vive la Experiencia</strong>
          </p>

          <hr />

          <div class="uk-margin-xlarge-top">
            <h4 class="uk-text-center" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Síguenos en Redes Sociales</h4>

            <div class="uk-child-width-expand@s uk-text-center" uk-grid uk-scrollspy="cls: uk-animation-fade; delay: 500; repeat: true">>
              <a
                target="BLANK"
                  href="https://www.facebook.com/kanajbubbleglamping/"
                  class="large-icon"
              >
                <span uk-icon="facebook"></span> <small>Facebook</small>
              </a>
              <a
                href="https://www.instagram.com/kanajbubbleglamping/"
                target="BLANK"
                class="large-icon"
              >
                <span uk-icon="instagram"></span> <small>Instagram</small>
              </a>
            </div>
          </div>
        </div>
        <div class="uk-width-1-3@m uk-flex-first" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
          <img
            src="../assets/foto_1.jpg"
            alt="Image"
          />
        </div>
      </div>
    </div>

    <PetFriendly :nodesktop='true'/>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /srcz
import { mapActions } from 'vuex';
import moment from 'moment-timezone';
import Slider from '../components/Slider.vue';
import Footer from '../components/Footer.vue';
import Calendar from '../components/Calendar.vue';
import PetFriendly from '../components/PetFriendly.vue';

export default {
  name: 'Home',
  components: {
    Slider,
    Footer,
    Calendar,
    PetFriendly,
  },
  data() {
    return {
      new_events: [],
      fechas: [],
    };
  },
  methods: {
    ...mapActions(['cargarLocalStorage']),
    updateCalendars(newEvent) {
      this.new_events.push(newEvent);
    },

    fechaHijo(date) {
      if (this.fechas.length === 2) {
        this.fechas = [this.fechas[1], date];
      } else {
        this.fechas.push(date);
      }

      // order array
      if (this.fechas.length === 1) {
        this.fechas[1] = this.fechas[0];
      } else {
        const start = moment(this.fechas[0]);
        const end = moment(this.fechas[1]);

        if (start > end) {
          const tmp = this.fechas[0];
          this.fechas[0] = this.fechas[1];
          this.fechas[1] = tmp;
        }
      }
    },

  },
  created() {
    this.cargarLocalStorage();
  },

};
</script>

<style>
.w-100{
  width: 100%;
}

.dark {
  color: #222;
}

.large-icon{
  font-size: 1.6em;
}

.avan {
  width: 1280;
  max-height: 853 !important;
}
.margin-top-h{
  padding-top: 60px;
}

.slider-image{
  max-width: 400px;
}

.app-color{
  color: #1b4225 !important;
}
.app-bg{
  background-color: #1b4225 !important;
  color: white;
}

.block{
  display: block;
  margin: 5px auto;
}

.large-icon, .large-text{
  font-size: 1.6em !important;
}

.transparente {
  background-color: rgb(0, 144, 37, 0.6);
  color: transparent;
  height: 10px;
  font-size: 1em;
}

.transparenterojo {
  background-color: rgb(210, 25, 25, 0.4) !important;
  color: transparent;
  height: 10px;
  font-size: 1em;
}

@media (min-width: 720px){
  .calendar{
    width: 90%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}

.mls{
  margin-right: 7px;
}

@media (max-width: 719px){
  .calendar{
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    }
}

</style>
