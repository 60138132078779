<template>
  <!-- eslint-disable max-len -->

  <div>
    <footer id="page-footer" class="uk-margin-top">
      <div class="uk-container uk-padding-small">
        <div class="uk-section uk-section-muted" id="color">
          <div class="uk-container">
            <div class="uk-grid-match uk-child-width-1-4@m" uk-grid>

              <div>
                <h3 class="uk-text-left">Mapa del Sitio</h3>

                   <a
                  href="/en-construccion"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="info"></span> Nosotros
                  </a>

                   <a
                  href="/en-construccion"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="image"></span> Galería
                  </a>
                   <a
                  href="/en-construccion"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="bookmark"></span> Actividades
                  </a>
                   <a
                  href="/en-construccion"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="calendar"></span> Paquetes y Eventos
                  </a>
                  <a
                  href="/ubicacion-kanaj"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="location"></span> Ubicación
                  </a>
                  <a
                    target="_blank"
                    href="https://kanaj.s3.us-east-2.amazonaws.com/reglamento.pdf"
                    class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="list"></span> Reglamento
                  </a>

              </div>

              <div>
                <h3 class="uk-text-left">Síguenos</h3>
                <a
                  href="https://www.instagram.com/kanajbubbleglamping/"
                  target="BLANK"
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="instagram"></span> kanajbubbleglamping
                </a>
                <a
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2"
                  target="BLANK"
                  href="https://www.facebook.com/kanajbubbleglamping/">
                  <span uk-icon="facebook"></span> kanajbubbleglamping
                </a>
                <a
                  class="uk-button uk-button-text btnword uk-text-left footerbtn2">
                  <span uk-icon="phone"></span> (618) 149 5288
                </a>

              </div>

              <!-- <div>
                <div>
                  <h3 class="uk-margin-bottom uk-text-left">AYUDA</h3>
                  <a
                    href="https://www.facebook.com/SuzukiAutosDurango"
                    target="_blank"
                    class="uk-button uk-button-text btnword uk-text-left miclase footerbtn2 "
                  >
                    <span uk-icon="facebook"></span> Facebook
                  </a>

                  <a
                    href="https://www.instagram.com/suzukidgo/"
                    target="_blank"
                    class="uk-button uk-button-text btnword uk-text-left footerbtn2"
                  >
                    <span uk-icon="instagram"></span> Instagram
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCLmciGikSEN3KdDRD5Nw9Vg?view_as=subscriber"
                    target="_blank"
                    class="uk-button uk-button-text btnword uk-text-left footerbtn2"
                  >
                    <span uk-icon="youtube"></span> Youtube
                  </a>
                </div>

                <div>
                  <h3 class="uk-margin-bottom uk-text-left">Contacto</h3>

                  <a
                    href="tel:6181503950"
                    target="_blank"
                    class="link-nodecoration uk-button-text btnword uk-text-left miclase"
                  >
                    <span uk-icon="receiver"></span> (618) 150-3950
                  </a>

                  <a
                    href="/contacto"
                    target="_blank"
                    class="uk-text-justify uk-display-block link-nodecoration uk-button-text btnword uk-text-left miclase uk-margin-medium-top"
                  >
                    <span uk-icon="location"></span>
                    Blvd. Francisco Villa 4001, Blvd. Francisco Villa. Colonia
                    20 de Noviembre Victoria de Durango.
                  </a>
                </div>
              </div>

              <div class="uk-width">
                <hr />
                <div class="uk-grid uk-child-width-expand">
                  <a class="none-style uk-text-small">
                    © 2020 SUZUKI MOTOR DE MÉXICO. TODOS LOS DERECHOS RESERVADOS
                  </a>

                  <div class="uk-text-right">
                    <a
                      href="https://cdn.marciano.space/suzuki/legal/150429-Aviso-de-Privacidad-Integral.pdf"
                      target="_blank"
                      class=" none-style uk-margin-small-left@s uk-text-small uk-padding-small"
                    >
                      Aviso de privacidad
                    </a>

                    <a
                      href="https://cdn.marciano.space/suzuki/legal/Formato-solicitud-derechos-ARCO.pdf"
                      target="_blank"
                      class="none-style uk-margin-small-left@s uk-text-small uk-padding-small"
                    >
                      Formato Arco
                    </a>

                    <a
                      class="none-style uk-margin-small-left@s uk-text-small uk-padding-small"
                      href="/terminos-y-condiciones"
                    >
                      Términos y condiciones
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
#page-footer {
  margin-top: 100px !important;
}

h3 {
  color: #ffffff;
  margin-bottom: 5px;
}

#page-footer {
  background-color: #000;
  color: #ffffff;
}

#color {
  background-color: #000;
}

.btnword {
  color: #ffffff;
}

.footerbtn {
  padding: 10px;
  vertical-align: center;
}

.footerbtn2 {
  height: 36px;
  vertical-align: middle;
  display: block;
  padding: 7px;
}
footer button:hover,
footer a:hover {
  color: #fff6ee !important;
  border-color: #fff6ee !important;
}

footer button:hover,
footer a:before {
  color: #fff6ee !important;
  border-color: #fff6ee !important;
}

#page-footer,
footer div {
  background-color: #gray-color !important;
}

#page-footer a{
  color: white !important;
}
</style>
