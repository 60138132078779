<template>

  <div>

    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>

      <ul class="uk-slider-items uk-child-width-1-1">
          <li>
            <video
              autoplay
              loop
              muted
              playsinline
              class="vi"
              width="1400"
              height="500"
              preload="yes"
            >
              <source src="../assets/GLAMPING_6.webm" type="video/webm" >
              <source src="../assets/GLAMPING_6.mp4" type="video/mp4">
            </video>
        </li>
        <li>
            <video
              autoplay
              loop
              muted
              playsinline
              class="vi"
              width="1400"
              height="500"
              preload="yes"
            >
              <source src="../assets/GLAMPING_7.webm" type="video/webm" >
              <source src="../assets/GLAMPING_7.mp4" type="video/mp4">
            </video>
        </li>
      </ul>

      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

    </div>
  </div>

  
</template>

<script>
export default {
  name: 'Slider',
};
</script>

<style>

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1000px) {
  .fixslider{
    max-height: 500px !important;
  }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 992px) {
 .fixslider{
    max-height: 380px !important;
  }
 }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fixslider{
    max-height: 180px !important;
  }
 }

.fixui{
  min-height: 160px !important;
}

.vi{
    width: 100%    !important;
    height: auto   !important;
}

</style>
