/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// eslint-disable-next-line import/no-named-as-default
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/en-construccion',
    name: 'Construction',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Construction.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import(/* webpackChunkName: "Registro" */ '../views/Registro.vue'),
    beforeEnter: (to, from, next) => {
      console.log(store.getters.usuarioAutenticado);
      if (store.getters.usuarioAutenticado) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      console.log(store.getters.usuarioAutenticado);
      if (store.getters.usuarioAutenticado) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/selection-room/',
    name: 'Seleccionar',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Seleccionar.vue'),

  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Checkout.vue'),

  },
  {
    path: '/completado',
    name: 'Complete',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Complete.vue'),

  },
  {
    path: '/forget-password',
    name: 'ForgetPass',
    component: () => import(/* webpackChunkName: "Login" */ '../views/ForgetPass.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePass',
    component: () => import(/* webpackChunkName: "Login" */ '../views/ChangePass.vue'),
  },
  {
    path: '/registro-checkout',
    name: 'RegistroCheckout',
    component: () => import(/* webpackChunkName: "Login" */ '../views/RegistroCheckout.vue'),
  },

  {
    path: '/reservations',
    name: 'Reservaciones',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Reservaciones.vue'),
  },
  {
    path: '/terminos-condiciones',
    name: 'Terminos',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Terminos.vue'),
  },
  {
    path: '/ubicacion-kanaj',
    name: 'Ubicacion',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Ubicacion.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // console.log(to.meta.rutaProtegida);
//   if (to.meta.rutaProtegida) {
//     if (store.getters.usuarioAutenticado) {
//       next();
//     } else {
//       next('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router;
