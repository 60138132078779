<template>

  <div>
    <div v-if="!nodesktop && show" >
      <img src="../assets/bl-pet-firendly.png" class="pet-friendly uk-visible@m"/>
    </div>

    <div class="pet-friendly-footer uk-hidden@m">
      <i class="fas fa-paw"></i> Pet Friendly
    </div>
  </div>

</template>

<script>
export default {
  name: 'PetFriendly',
  props: ['nodesktop'],
  data() {
    return {
      show: true
    }
  },
  mounted() {
    var vue = this;
    document.addEventListener('scroll', scroll => {
      const pageoff = window.pageYOffset;
      const footeroff = document.getElementById('page-footer').offsetTop - document.getElementById('page-footer').offsetHeight * 2;
      vue.show =  (pageoff < footeroff);
    });
  },
};
</script>

<style>
.pet-friendly-footer{
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 999;
  background-color: #518040;
  font-weight: bolder;
  letter-spacing: 3px;
  color: white;
  font-size: 1.2em;
  padding: 10px 15px 15px 30px;
}

.pet-friendly{
  width: 7%;
  position: fixed;
  z-index: 999;
  bottom: 80px;
  left: 80px;;
}

</style>
