<template>
 <!-- eslint-disable guard-for-in  -->
<!-- /* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-syntax */ -->
  <v-row>
    <v-col>
      <v-sheet>
        <v-toolbar flat color="white">
          <v-btn fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title> {{ title }} {{ today }} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet>
        <v-calendar
          class="calendar-h"
          ref="calendar"
          v-model="focus"
          :day-format="nosequehace"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          locale="es"
          :short-weekdays="false"
        ></v-calendar>
           <!-- eslint-disable-next-line vue/no-parsing-error -->
          </v-card>
         <!-- eslint-disable-next-line vue/no-parsing-error -->
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone';
import _ from 'underscore';
import { SERVER_HOST } from '../App.vue';

export default {
  watch: {
  },
  components: {},
  beforeRouteEnter() {
    this.updateCalendarRender();
  },
  data() {
    return {
      pila_count: 1,
      selected_dates: [],
      start: null,
      end: null,
      changedRender: false,
      fetched_events: [],
      newFetched_events: [], // click selected events
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        '4day': '4 Días',
      },
      date: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      // Adicionales...
      name: null,
      details: null,
      color: '#009025',
      dialog: false,
      currentlyEditing: null,
      currents: [],
      events: [],
    };
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? '' : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? '' : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`;
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`;

        default:
          return '';
      }
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long',
      });
    },
  },
  props: ['newEvents', 'label'],
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.getEvents();
  },
  updated() {
  },
  methods: {

    updateCalendarRender() {
      const vueThis = this;


      if (this.selected_dates[0] && this.selected_dates[1]) {
        
        const start = moment(this.selected_dates[0].start);
        const end = moment(this.selected_dates[1].start);

        const starts = this.events.map(event => event.start);

        if (start < end) {
          let crr = start;
          while(crr <= end) {
            if (starts.includes(crr.format('YYYY-MM-DD'))) {
              this.events.push({
                name: '',
                details: '',
                start: crr.format('YYYY-MM-DD'),
                end: crr.format('YYYY-MM-DD'),
                color: '#1976d2',
              });
            }
            crr.add(1, 'day');
          }
        }else{
          let crr = end;
          while(crr <= start) {
            if (starts.includes(crr.format('YYYY-MM-DD'))) {
              this.events.push({
                name: '',
                details: '',
                start: crr.format('YYYY-MM-DD'),
                end: crr.format('YYYY-MM-DD'),
                color: '#1976d2',
              });
            }
            crr.add(1, 'day');
          }
        }

      }else{
        // SET SELECTED DATES
        for (const eventDate in this.selected_dates) {
          this.events.push(this.selected_dates[eventDate]);
        }
      }


      setTimeout(() => {

        document.querySelectorAll('.v-calendar-weekly__day').forEach((el) => {
          const padre = el;
          const event = padre.children[1];
          // eslint-disable-next-line camelcase
          const eventAndCurrent = padre.children[2];

          if (typeof (event) === 'undefined' || event == null) {
            padre.classList.add('notavailable');
            return;
          }

          // INVALIDADOS DIAS QUE NO ESTAN DISPONIBLES
          if (eventAndCurrent) {
            if (event) {
              // VERDE
              // eslint-disable-next-line eqeqeq

              // AZUL
              // eslint-disable-next-line eqeqeq
              if (eventAndCurrent.style.backgroundColor === 'rgb(25, 118, 210)') {

                if (padre.classList.contains('paul')) {
                  padre.classList.remove('paul');
                }
                event.classList.add('invisible');
                padre.classList.add('elias');

                eventAndCurrent.classList.add('invisible');
                return;
              }else{
                // VERDE
                // eslint-disable-next-line eqeqeq
                if (event.style.backgroundColor == 'rgb(0, 144, 37)') {
                  padre.classList.add('paul');
                  padre.classList.add('isgreen');
                }

                event.classList.add('invisible');
                }
            }
          }

        });
        return;

        document.querySelectorAll('.v-calendar-weekly__day').forEach((el) => {
          const padre = el;
          const event = padre.children[1];
          // eslint-disable-next-line camelcase
          const event_and_current = padre.children[2];

          // eslint-disable-next-line camelcase
          if (event_and_current) {
            if (event) {
              // VERDE
              // eslint-disable-next-line eqeqeq

              // AZUL
              if (event_and_current.style.backgroundColor === 'rgb(25, 118, 210)') {
                if (vueThis.currents.length === 2) {
                  const old = vueThis.currents[0];
                  old.classList.remove('elias');
                  vueThis.currents = [vueThis.currents[1]];

                  vueThis.currents.push(padre);
                  padre.classList.add('elias');
                  if (padre.classList.contains('paul')) padre.classList.remove('paul');
                } else {
                  vueThis.currents.push(padre);
                  padre.classList.add('elias');
                  if (padre.classList.contains('paul')) padre.classList.remove('paul');
                }
              }

              // VERDE
              // eslint-disable-next-line eqeqeq
              if (event.style.backgroundColor == 'rgb(0, 144, 37)') {
                if (padre.classList.contains('elias')) {
                  padre.classList.remove('elias');
                }
                padre.classList.add('paul');
                padre.classList.add('isgreen');
              }

              event.classList.add('invisible');
              //event.remove();
            }
          }

          if (padre.classList.contains('isgreen')
            && !padre.classList.contains('elias')) {
            padre.classList.add('paul');
          }
        });
      
      }, 80);
    },
    nosequehace(f) {
      if (typeof this.events[0] === 'undefined') return f.day;
      if (f.day === this.events[0].start.split('-')[2]) {
        return `-${f.day}-`;
      }
      return f.day;
    },
    async updateEvent(evento) {
      try {
        // eslint-disable-next-line no-undef
        await db
          .collection('eventos')
          .doc(evento.id)
          .update({
            name: evento.name,
            details: evento.details,
          });

        this.selectedOpen = false;
        this.currentlyEditing = null;
      } catch (error) {
        console.log(error);
      }
    },
    editEvent(id) {
      this.currentlyEditing = id;
    },
    async deleteEvent(event) {
      try {
        // eslint-disable-next-line no-undef
        await db
          .collection('eventos')
          .doc(event.id)
          .delete();
        this.selectedOpen = false;
        this.getEvents();
      } catch (error) {
        console.log(error);
      }
    },

    async getEvents(year, month) {
      try {
        let url = `${SERVER_HOST}/api/v1/dates-availables?`;
        if (year != null) {          
          url += ('year=' + year);
        }

        if (month  != null) {          
          url += ('&month=' + month);
        }

        const data = await fetch(url);

        // eslint-disable-next-line camelcase
        const json_data = await data.json();

        // eslint-disable-next-line array-callback-return
        // Parse Json API
        let mapedData = json_data.data.filter((e) => moment(e.attributes.start) >= moment() );


        mapedData = mapedData.map((e) => ({
          color: e.attributes.color,
          details: e.attributes.details,
          end: e.attributes.end,
          start: e.attributes.start,
        }));

        // Get uniqess
        this.events = mapedData;


        this.updateCalendarRender();

        // this.$refs.calendar.updateTimes();
      } catch (error) {
        console.log(error);
      }
    },

    async viewDay({ date }) {
      // Validar fechas del pasado
      if (moment(date) < moment(this.today)) {
        return;
      }

      // console.log(events)
      let starts = this.events.map((a) => {
        return a.start;
      });

      if (!starts.includes(date)) {
        return;
      }

      const eventData = {
        name: '',
        details: '',
        start: date,
        end: date,
        color: '#1976d2',
      };

      this.pila_count += 1;

      // push new selected
      this.selected_dates[this.pila_count % 2] = eventData;

      // init
      this.newFetched_events = [];

      this.events = this.events.filter((a)=>{
        return a.color != '#1976d2';
      });

      // eslint-disable-next-line no-restricted-syntax
      // eslint-disable-next-line guard-for-in
      this.updateCalendarRender();

      this.$emit('paul', date);
    },

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.events = [];
      this.$refs.calendar.prev();
      this.getEvents(this.start.year, this.start.month - 1);
    },
    next() {
      this.events = [];
      this.$refs.calendar.next();
      this.getEvents(this.start.year, this.start.month + 1);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // eslint-disable-next-line no-return-assign
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // eslint-disable-next-line max-len
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start;
      this.end = end;
      this.updateCalendarRender();
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    },
  },
};
</script>

<style lang="css">
  .v-event-more{
    display: none !important;
  }
  .invisible{
    display: none !important;
    z-index: 1 !important;
  }
  .notavailable{
    background-color: rgb(210, 25, 25, 0.4) !important;
    color: white !important;
    transition: 200ms;
  }

  .elias{
    background-color: rgba(25, 118, 210, 0.6) !important;
    color: white !important;
    transition: 200ms;
  }
  .elias button{
    color: white !important;
    transition: 200ms;
  }
  .paul{
    background-color: rgb(0, 144, 37, 0.6) !important;
    color: white !important;
    transition: 200ms;
  }
  .v-past{
    background-color: rgb(210, 25, 25, 0.4) !important;
    color: white !important;
  }
  .v-calendar-weekly__head-weekday{
    color: #060606 !important;
  }

  .calendar-h{
    display: block;
    margin: 0 auto;
  }

  @media (max-height: 1080px) and (min-width: 720px) {
    .calendar-h{
      width: 100% !important;
      height: 45vh !important;
    }
  }

  @media (max-height: 760px) and (min-width: 720px) {
    .calendar-h{
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (max-height: 725.98px)  and (min-width: 720px){
    .calendar-h{
      width: 80% !important;
      height: auto !important;
    }
  }

  @media (max-height: 799.98px)  and (max-width: 1080px){
    .calendar-h{
      width: 100% !important;
      height: auto;
    }
  }

  .v-calendar-weekly__day{
    transition: 200ms;
  }

</style>
