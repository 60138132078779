/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment-timezone';
import router from '../router';

// eslint-disable-next-line import/extensions
import { SERVER_HOST } from '../App';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    usuario: null,
    card: null,
    rooms: null,
    reservableDay: [],
    errors: {
      tipo: null,
      mensaje: null,
    },
    errorsLogin: {
      tipo: null,
      mensaje: null,
    },
    success: [],
    loading: false,
    reservations: [],
    page: 1,
    pages: null,
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    cleanDataReservations(state, payload) {
      state.reservations = [];
      state.pages = null;
    },
    finCheckout(state, payload) {
      state.reservableDay = payload;
    },
    rooms(state, payload) {
      state.rooms = payload;
    },
    setUsuario(state, payload) {
      state.usuario = payload;
    },

    // eslint-disable-next-line consistent-return
    setError(state, payload) {
      switch (payload) {
        case 'USER_EMAIL_ALREADY_TAKEN':
          return state.errors = {
            tipo: 'Registro',
            mensaje: 'Este correo ya ha sido registrado',
          };
          // eslint-disable-next-line no-unreachable
          break;

        case 'USER_EMAIL_NOT_FOUND':
          return state.errors = {
            tipo: 'Correo no encontrado',
            mensaje: 'El email no esta registrado',
          };
          // eslint-disable-next-line no-unreachable
          break;

        default:
          return state.errors = {
            tipo: null,
            mensaje: null,
          };
          // eslint-disable-next-line no-unreachable
          break;
      }
    },

    setErrorLogin(state, payload) {
      switch (payload) {
        case 'USER_EMAIL_NOT_FOUND':
        case 'USER_WRONG_PASSWORD':
          return state.errorsLogin = {
            tipo: 'Login',
            mensaje: 'El email o la contraseña no son correctos',
          };
          // eslint-disable-next-line no-unreachable
          break;

        default:
          return state.errorsLogin = {
            tipo: null,
            mensaje: null,
          };
          // eslint-disable-next-line no-unreachable
          break;
      }
    },

    setSuccess(state, payload) {
      state.success = payload;
    },

    loadingRequest(state, payload) {
      state.loading = payload;
    },

    setReservations(state, payload) {
      payload.data.forEach((element) => {
        state.reservations.push(element);
      });
      // state.reservations = payload;
    },

    setLastPages(state, payload) {
      state.pages = payload;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    cargarLocalStorage({ commit }) {
      if (localStorage.getItem('user')) {
        const usuario = JSON.parse(localStorage.getItem('user'));
        console.log(usuario);

        // eslint-disable-next-line keyword-spacing
        if(!usuario) {
          return;
        }
        const dateExpire = [];
        const yearExpire = parseInt(usuario.expire_at.split('T')[0].split('-')[0], 10);
        const monthExpire = parseInt(usuario.expire_at.split('T')[0].split('-')[1], 10) - 1;
        const dayExpire = parseInt(usuario.expire_at.split('T')[0].split('-')[2], 10);
        dateExpire.push(yearExpire, monthExpire, dayExpire);
        // console.log(dateExpire);
        const momentUserExpire = moment(dateExpire).tz('America/Mexico_City');
        const now = moment().tz('America/Mexico_City');
        commit('setUsuario', usuario);

        // console.log(momentUserExpire);
        // console.log(now);

        if (now >= momentUserExpire) {
          localStorage.removeItem('user');
          router.push('/login');
        }
      } else {
        return commit('setUsuario', null);
      }
    },

    async updateProfile({ commit }) {
      try {
        const headers = {
          'Content-Type': 'application/json',
        };

        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          headers.Authorization = `Bearer ${user.user_token}`;
        }

        const res = await fetch(`${SERVER_HOST}/api/v1/me`, {
          method: 'POST',
          headers,
        });

        if (res.status === 401) {
          throw Error('NOT AUTHORIZED');
        }

        const userDB = await res.json();
        commit('setUsuario', userDB);

        const data = JSON.stringify(userDB);
        localStorage.setItem('user', data);
      } catch (error) {
        console.log('updateProfile', error);
        localStorage.setItem('user', null);
        commit('setUsuario', null);
      }
    },

    cerrarSesion({ commit }) {
      commit('setUsuario', null);
      router.push('/login');
      localStorage.removeItem('user');
    },
    // eslint-disable-next-line consistent-return
    async registroUsuarios({ commit }, usuario) {
      console.log(usuario);
      try {
        const res = await fetch(`${SERVER_HOST}/api/v1/sign-up`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            user: {
              name: usuario.name,
              lastname: usuario.lastname,
              email: usuario.email,
              password: usuario.password,
              phone: usuario.phone.nationalNumber,
              countryCallingCode: usuario.phone.countryCallingCode,
              countryCode: usuario.phone.countryCode,
              numberPhone: usuario.phone.number,
              user_token: true,
            },
          }),
        });
        // console.log(res);
        const userDB = await res.json();
        console.log(userDB);
        if (userDB.error) {
          return commit('setError', userDB.error.message);
        }
        commit('setUsuario', userDB);
        commit('setError', null);
        const data = JSON.stringify(userDB);
        console.log(data);
        localStorage.setItem('user', data);
        router.push('/');
      } catch (error) {
        console.log(error);
      }
    },

    // eslint-disable-next-line consistent-return
    async iniciarSesion({ commit }, usuario) {
      try {
        commit('loadingRequest', true);
        // console.log(usuario);
        const res = await fetch(`${SERVER_HOST}/api/v1/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            email: usuario.email,
            password: usuario.password,
            user_token: true,
          }),
        });
        commit('loadingRequest', false);
        // console.log(res);
        const userDB = await res.json();
        console.log(userDB);
        // console.log(userDB.error);
        if (userDB.error) {
          console.log(userDB.error);
          return commit('setErrorLogin', userDB.error.message);
        // eslint-disable-next-line no-else-return
        }
        // localStorage.setItem('user_token', userDB.user_token);
        commit('setUsuario', userDB);
        commit('setErrorLogin', null);
        const data = JSON.stringify(userDB);
        localStorage.setItem('user', data);
        router.push('/');
      } catch (error) {
        console.log(error);
      }
    },

    async enviarTarjeta({ commit }, datos) {
      try {
        const headers = {
          'Content-Type': 'application/json',
        };

        const user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        if (user) {
          headers.Authorization = `Bearer ${user.user_token}`;
        }

        // console.log("enviarTarjeta", datos)

        const res = await fetch(`${SERVER_HOST}/api/v1/ck/new-source`, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            token_id: datos.card.tokentarjeta,
            name: datos.card.nameUser,
            email: datos.card.email,
            phone: datos.card.phone.nationalNumber,
            countryCode: datos.card.phone.countryCode,
            countryCallingCode: datos.card.phone.countryCallingCode,
          }),
        });

        // eslint-disable-next-line no-unused-vars
        const data = await res.json();
        console.log(data);
        console.log(datos.card.callback);
        datos.card.callback();
        commit('');
      } catch (error) {
        console.log(error);
      }
    },

    async pintarRoom({ commit }, fechas) {
      console.log(fechas);
      const params = new URLSearchParams({
        start: fechas.fecha,
        end: fechas.fecha2,
      });
      try {
        const res = await fetch(`${SERVER_HOST}/api/v1/places-availables?${params}`);
        const dataDB = await res.json();
        console.log(dataDB.data);
        commit('rooms', dataDB.data);
      } catch (error) {
        console.log(error);
      }
    },

    // eslint-disable-next-line no-unused-vars
    async diasReserva({ commit, state }, dias) {
      console.log(dias);
      try {
        const res = await fetch(`${SERVER_HOST}/api/v1/checkout/${dias.type}/${dias.id}?start=${dias.start}&end=${dias.end}`);
        const data = await res.json();
        commit('finCheckout', data.data);
      } catch (error) {
        console.log(error);
      }
    },

    // eslint-disable-next-line consistent-return
    async makeReservation({ commit, state }, datos) {
      console.log(datos);
      try {
        const headers = {
          'Content-Type': 'application/json',
        };

        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          headers.Authorization = `Bearer ${user.user_token}`;
        }

        const res = await fetch(`${SERVER_HOST}/api/v1/ck/make-charge`, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            name: datos.name,
            email: datos.email,
            payment: datos.payment_method,
            reservable_type: datos.type,
            reservable_id: datos.id,
            reservation_days: state.reservableDay.map((el) => el.attributes),
            phone: datos.number,
            countryCode: datos.countryCode,
            formattedNumber: datos.formattedNumber,
            countryCallingCode: datos.countryCallingCode,
          }),
        });

        const dataDB = await res.json();

        if (dataDB.error) {
          console.log(dataDB.error);
          datos.loading();
          Vue.swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: dataDB.error.errors[0].message,
            // footer: '<a href>Why do I have this issue?</a>',
          });
          return commit('setError', dataDB.error);
        }

        // eslint-disable-next-line eqeqeq
        if (dataDB.status == 'paid' || dataDB.status == 'pay-saved') {
          const events = state.reservableDay.map((el) => ({
            summary: 'Reservacion KANAJ',
            location: '',
            description: 'Reservacion KANAJ Bubble Camping Durango',
            start: {
              dateTime: `${el.attributes.start}T20:00:00-06:00`,
              timeZone: 'America/Mexico_City',
            },
            end: {
              dateTime: `${el.attributes.end}T23:00:00-06:00`,
              timeZone: 'America/Mexico_City',
            },
            recurrence: [],
            attendees: [],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'popup', minutes: 60 * 24 },
              ],
            },
          }));

          localStorage.setItem('reservatedEvents', JSON.stringify(events));

          router.push({
            name: 'Complete',
            query: {
              payment_method: dataDB.type,
              reference: dataDB.order.charges[0].payment_method.reference,
              amount: parseFloat(dataDB.order.amount) / 100.0,
            },
          });
        } else {
          datos.loading();
        }
        commit();
      } catch (error) {
        console.log(error);
      }
    },

    async recoverPass({ commit }, email) {
      console.log(email.email);
      try {
        const res = await fetch(`${SERVER_HOST}/api/v1/change-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email.email,
          }),
        });

        const dataDB = await res.json();
        console.log(dataDB);
        if (dataDB.error) {
          console.log(dataDB.error.message);
          return commit('setError', dataDB.error.message);
        }

        commit('setSuccess', dataDB);
      } catch (error) {
        console.log(error);
      }
    },

    async updatePass({ commit }, objeto) {
      console.log(objeto);
      try {
        const res = await fetch(`${SERVER_HOST}/api/v1/update-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: objeto.password.password,
            token: objeto.token,
          }),
        });

        const dateDB = await res.json();
        console.log(dateDB);
        if (dateDB.error) {
          console.log(dateDB.error);
          return commit('setError', dateDB.error);
        }
        Vue.swal.fire({
          icon: 'success',
          title: 'Se ha cambiado la contraseña con exito!',
          // text: dateDB.message,
          // footer: '<a href>Why do I have this issue?</a>',
        }).then(() => {
          router.push('/login');
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getReservation({ commit }, params = {
      url: '/api/v1/me/reservations',
    }) {
      if (params.url === '/api/v1/me/reservations') {
        commit('cleanDataReservations', null);
      }

      try {
        const res = await fetch(`${SERVER_HOST}${params.url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).user_token}`,
          },
        });

        const dataDB = await res.json();

        if (dataDB.error) {
          commit('setError', dataDB.error);
        }
        commit('setReservations', dataDB);
        commit('setLastPages', dataDB.links.next);
      } catch (error) {
        console.log(error);
      }
    },

    limpiarErrores({ commit }) {
      commit('setError', null);
      commit('setErrorLogin', null);
    },
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.usuario;
    },
  },
  modules: {},
});
