import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueCardFormat from 'vue-credit-card-validation';
import VueMask from 'v-mask';
import VueGeoLocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAFiaau3lOSdKn6kVxqIwdnMr0zYFEvtuo',
  },
});

Vue.use(VueGeoLocation);
Vue.use(VueSweetalert2);
Vue.use(VueMask);

Vue.use(VueCardFormat);

Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
